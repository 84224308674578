<script>
	import { createEventDispatcher, getContext } from "svelte";
	import {
		format,
		getTimeFormat,
		localeContext,
		popupClick,
	} from "@xbs/lib-scheduler";
	import { startOfDay } from "date-fns";

	export let event = {};
	export let calendar = {};
	export let readonly = false;
	const { showModal } = getContext("wx-helpers");
	const locale = getContext(localeContext);
	const _ = locale.getGroup("scheduler");
	const _dateFnsLocale = locale.getRaw().dateFnsLocale;

	const dispatch = createEventDispatcher();
	$: ({ start_date, allDay, border, background, text } = event);
	$: start = allDay ? startOfDay(start_date) : start_date;
	$: background =
		border ||
		calendar.color.border ||
		background ||
		calendar.color.background;

	function closeEventInfo() {
		dispatch("action", {
			action: "close-event-info",
			data: {},
		});
	}

	function clickHandle(name) {
		popupClick(name, event, dispatch, closeEventInfo, showModal, _);
	}

</script>

<div class="wx-event-calendar-popup_wrapper">
	<span>
		<div class="wx-event-calendar-header">
			<div>
				<span
					style="background-color: {background}"
					class="wx-event-calendar-checkbox" />
				<span
					class="wx-event-calendar-title">{calendar.label || ''}</span>
			</div>
			<i
				class="wxi-close wx-event-calendar-close"
				on:click={closeEventInfo} />
		</div>
	</span>
	<div class="wx-event-calendar-info">
		<div class="wx-event-calendar-name">{text}</div>
		<div class="wx-event-calendar-date">
			{format( start, `PPPP, ${getTimeFormat(start, locale)}`, { locale: _dateFnsLocale } )}
		</div>
		{#if !event.readonly && !readonly && !calendar?.readonly}
			<div class="wx-event-calendar-controls">
				<i
					class="wxi-edit wx-event-calendar-control"
					on:click={() => clickHandle('edit')}><span
						class="wx-event-calendar-label">{_('Edit event')}</span></i>
				<i
					class="wxi-delete wx-event-calendar-control"
					on:click={() => clickHandle('delete')}><span
						class="wx-event-calendar-label">{_('Delete event')}</span></i>
			</div>
		{/if}
	</div>
</div>

<style>
	.wx-event-calendar-info {
		width: 300px;
		display: flex;
		flex-direction: column;
	}
	.wx-event-calendar-checkbox {
		display: inline-block;
		width: 9px;
		height: 9px;
		border-radius: 2px;
		margin-right: 4px;
	}

	.wx-event-calendar-name {
		font-size: var(--wx-font-size-md);
		font-weight: var(--wx-font-weight-b);
		margin: 5px 0;
	}

	.wx-event-calendar-date {
		text-transform: capitalize;
	}

	.wx-event-calendar-controls {
		margin-top: 7px;
		display: flex;
		gap: 8px;
	}

	.wx-event-calendar-control {
		display: flex;
		align-items: center;
		gap: 4px;
		cursor: pointer;
		font-style: normal;
		color: var(--wx-color-primary);
	}
	.wx-event-calendar-label {
		color: var(--wx-color-font);
	}

	.wx-event-calendar-popup_wrapper {
		padding: 10px 20px 15px 20px;
		max-width: 500px;
		font-family: var(--wx-font-family);
		line-height: var(--wx-line-height);
		line-height: var(--wx-line-height-md);
	}

	.wx-event-calendar-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: default;
	}

	.wx-event-calendar-close {
		cursor: pointer;
		color: var(--wx-icon-color);
		font-size: var(--wx-font-size-md);
	}

	.wx-event-calendar-title {
		font-size: var(--wx-font-size-sm);
	}

</style>
