<script lang="ts">
	export let template: string | ((config: Record<string, any>) => void);

	$: htmlString =
		typeof template === "function"
			? template({ ...$$restProps })
			: template;

</script>

{#if htmlString}
	{@html htmlString}
{/if}

<style>
</style>
