<script>
	import { slide, fade, fly } from "svelte/transition";
	import { localeContext } from "@xbs/lib-scheduler";

	import { getContext, createEventDispatcher } from "svelte";
	import Checkbox from "./Checkbox.svelte";

	export let calendars = [];

	let isVisable = true;
	const dispatch = createEventDispatcher();
	const _ = getContext(localeContext).getGroup("scheduler");
	const config = getContext("schedulerConfig");
	$: ({ readonly } = $config);

	function toggle() {
		isVisable = !isVisable;
	}

	function add() {
		dispatch("sidebarAction", { action: "add" });
	}

</script>

<div class="wx-event-calendar-wrapper">
	<div class="wx-event-calendar-title" on:click={toggle}>
		<div class="wx-event-calendar-name">{_('Calendars')}</div>
		<div class="wx-event-calendar-buttons">
			{#if !readonly}
				<i
					class="wxi wxi-plus"
					data-id="add-calendar"
					on:click|stopPropagation={add} />
			{/if}
			<i
				data-id="toggle-calendar-visibility"
				class="wxi wxi-angle-{isVisable ? 'down' : 'up'}" />
		</div>
	</div>
	{#if isVisable}
		<div class="wx-event-calendar-calendars-list">
			{#each calendars as calendar (calendar.id)}
				<div
					class="wx-event-calendar-calendar_item"
					in:fly={{ duration: 700, y: -20 }}
					out:fade={{ duration: 200 }}>
					<Checkbox {calendar} on:action on:sidebarAction />
				</div>
			{/each}
		</div>
	{/if}
</div>

<style>
	.wx-event-calendar-wrapper {
		display: flex;
		flex-direction: column;
		max-height: 100%;
		font-size: var(--wx-font-size);
		position: relative;
	}

	.wx-event-calendar-calendar_item,
	.wx-event-calendar-title {
		display: flex;
		align-items: center;
		cursor: pointer;
		user-select: none;
		border-top: var(--wx-border);
		width: 100%;
		height: 36px;
		flex-shrink: 0;
	}

	.wx-event-calendar-calendar_item:first-of-type {
		border-top: none;
	}
	.wx-event-calendar-calendars-list {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		overflow: auto;
		margin-top: 36px;
	}

	.wx-event-calendar-calendar_item:hover {
		background-color: var(--wx-background-alt);
	}

	.wx-event-calendar-title {
		justify-content: space-between;
		cursor: pointer;
		padding: 6px 12px;
		position: absolute;
		border-bottom: var(--wx-border);
	}
	.wx-event-calendar-name {
		font-weight: 600;
	}
	.wxi {
		color: var(--wx-color-font-disabled);
		font-size: var(--wx-line-height);
	}
	.wx-event-calendar-buttons {
		display: flex;
		align-items: center;
		gap: 8px;
	}

</style>
