<script>
	import { localeContext } from "@xbs/lib-scheduler";
	import { endOfDay, isPast } from "date-fns";
	import { createEventDispatcher, getContext } from "svelte";

	export let day;
	export let config;
	const dispatch = createEventDispatcher();
	const _ = getContext(localeContext).getGroup("scheduler");
	const commonConfig = getContext("schedulerConfig");
	$: ({ maxEventsPerCell } = config);
	$: ({ dimPastEvents } = $commonConfig);

	let style;

	$: {
		const top = `top:${day.showAll.top}px;`;
		const height = `height:${day.showAll.height}px;`;
		const lineHeight = `line-height:${day.showAll.height}px;`;
		const dimColor = dimPastEvents && isPast(endOfDay(day.value));
		const color = `--wx-show-all-color:${
			dimColor ? "var(--wx-color-font-alt)" : "var(--wx-color-font)"
		};`;
		const hover = `--wx-show-all-color-hover:${
			dimColor ? "var(--wx-color-font)" : "var(--wx-color-font-alt)"
		};`;
		style = `${top}${height}${lineHeight}${color}${hover}`;
	}

	function showAll() {
		dispatch("action", {
			action: "set-mode",
			data: { value: "day" },
		});
		dispatch("action", {
			action: "set-date",
			data: { value: day.value },
		});
	}

</script>

<div class="wx-event-calendar-today" {style} on:click={showAll}>
	{_('viewAll').replace('{count}', day.events - maxEventsPerCell)}
</div>

<style>
	.wx-event-calendar-today {
		position: absolute;
		width: 100%;
		padding: 0 6px;
		color: var(--wx-show-all-color);
		font-weight: var(--wx-font-weight-md);
		overflow: hidden;
		text-overflow: ellipsis;
		cursor: pointer;
	}
	.wx-event-calendar-today:hover {
		color: var(--wx-show-all-color-hover);
	}

</style>
