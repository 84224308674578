<script>
	import { getContext } from "svelte";

	export let section;
	export let colsWidth;
	export let sectionWidth;
	export let dates = [];
	const templates = getContext("schedulerTemplates");

</script>

<div
	class="wx-event-calendar-timeline-section wx-event-calendar-timeline-data"
	style="max-width: {sectionWidth}px;">
	<svelte:component this={templates.timelineSection} {section} />
</div>

{#each dates as date (date)}
	<div
		data-drag="both"
		class="wx-event-calendar-timeline-date wx-event-calendar-timeline-data"
		style="width: {colsWidth}px;"
		data-cell={date?.valueOf()} />
{/each}

<style>
	.wx-event-calendar-timeline-data {
		background-color: var(--wx-background);
		color: var(--wx-color-font);
		text-align: center;
		flex-shrink: 0;
		border-bottom: var(--wx-border);
		box-sizing: border-box;
		display: table-cell;
	}
	.wx-event-calendar-timeline-section {
		position: sticky;
		left: 0;
		background-color: var(--wx-background);
		flex-shrink: 0;
		z-index: 1;
		width: 100%;
		border-right: var(--wx-border);
	}
	.wx-event-calendar-timeline-date {
		border-right: var(--wx-border);
	}

</style>
