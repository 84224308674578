<script>
	import { Field, Select, Text, DatePicker } from "@wx/svelte-core";
	import { form } from "@xbs/lib-svelte";
	import { capitalizeFirstLetter, localeContext } from "@xbs/lib-scheduler";
	import { format, setDate, setMonth, setDay } from "date-fns";

	import { getContext } from "svelte";
	import WeekButtons from "./WeekButtons.svelte";

	export let value;
	export let start_date;
	const locale = getContext(localeContext);
	const _ = locale.getGroup("scheduler");
	const _dates = locale.getRaw().dateFnsLocale;

	const options = [
		{ id: "NEVER", label: _("Never") },
		{ id: "DAILY", label: `${_("Every")} ${_("Day").toLowerCase()}` },
		{ id: "WEEKLY", label: `${_("Every")} ${_("Week").toLowerCase()}` },
		{ id: "MONTHLY", label: `${_("Every")} ${_("Month").toLowerCase()}` },
		{ id: "YEARLY", label: `${_("Every")} ${_("Year").toLowerCase()}` },
		{ id: "WORKDAYS", label: `${_("Workdays")}` },
		{ id: "CUSTOM", label: _("Custom") },
	];
	const every = [
		{ id: "DAILY", label: _("Day") },
		{ id: "WEEKLY", label: _("Week") },
		{ id: "MONTHLY", label: _("Month") },
		{ id: "YEARLY", label: _("Year") },
	];

	const ends = [
		{ id: "NEVER", label: _("Never") },
		{ id: "AFTER", label: _("After") },
		{ id: "ON", label: _("On date") },
	];

	const values = form({}, val => {
		if (val.FREQ === "MONTHLY" || val.FREQ === "YEARLY") {
			val.BYDAY = [format(start_date, "EEEEEE").toLocaleUpperCase()];
		}
		value = val;
	});

	$: values.reset({ ...value });
	let setposOptions = [];
	$: {
		const weekDay = $values.weekDays.find(
			({ id }) => id === $values.BYDAY[0]
		).fullName;
		const date = setMonth(new Date(), $values.BYMONTH - 1);
		const month = capitalizeFirstLetter(
			format(date, "MMM", { locale: _dates })
		);
		const day = format(setDate(date, $values.BYMONTHDAY), "do", {
			locale: _dates,
		});
		const pos = format(setDay(date, $values.BYSETPOS - 1), "co", {
			locale: _dates,
		});

		const factor = $values.FREQ === "YEARLY" ? "Year" : "Month";

		setposOptions = [
			{
				id: "DAY",
				label: _(`recurringEvery${factor}Day`)
					.replace("{date}", day)
					.replace("{month}", month),
			},
			{
				id: "POS",
				label: _(`recurringEvery${factor}Pos`)
					.replace("{pos}", pos)
					.replace("{weekDay}", weekDay)
					.replace("{month}", month),
			},
		];
	}

</script>

<div class="wx-event-calendar-recurring-wrapper">
	<Field position="top" let:id>
		<Select {id} {options} bind:value={$values.freq} />
	</Field>
	{#if $values.freq === 'CUSTOM'}
		<Field position="top" let:id label={_('Every')}>
			<div class="wx-event-calendar-flex-col">
				<div class="wx-event-calendar-text-wrapper">
					<Text {id} bind:value={$values.INTERVAL} type="number" />
				</div>
				<Select {id} options={every} bind:value={$values.FREQ} />
			</div>
		</Field>
		{#if $values.FREQ === 'WEEKLY'}
			<Field position="top" let:id label={_('Day')}>
				<WeekButtons
					bind:value={$values.BYDAY}
					weekDays={$values.weekDays} />
			</Field>
		{/if}
		{#if $values.FREQ === 'MONTHLY' || $values.FREQ === 'YEARLY'}
			<Field>
				<Select options={setposOptions} bind:value={$values.byset} />
			</Field>
		{/if}

		<Field position="top" let:id label={_('Ends')}>
			<div class="wx-event-calendar-flex-col">
				<Select {id} options={ends} bind:value={$values.ends} />
				{#if $values.ends === 'AFTER'}
					<div class="wx-event-calendar-ends-a-wrapper">
						<div class="wx-event-calendar-text-wrapper">
							<Text
								{id}
								bind:value={$values.COUNT}
								type="number" />
						</div>
						{_('events').toLowerCase()}
					</div>
				{:else if $values.ends === 'ON'}
					<div class="wx-event-calendar-ends-a-wrapper">
						<DatePicker bind:value={$values.UNTIL} />
					</div>
				{/if}
			</div>
		</Field>
	{/if}
</div>

<style>
	.wx-event-calendar-flex-col {
		display: flex;
	}
	.wx-event-calendar-text-wrapper {
		width: 65px;
		flex-shrink: 0;
		margin-right: 10px;
	}
	:global(.wx-event-calendar-text-wrapper input) {
		text-align: center;
		-moz-appearance: textfield;
	}
	:global(
			.wx-event-calendar-text-wrapper input::-webkit-outer-spin-button,
			.wx-event-calendar-text-wrapper input::-webkit-inner-spin-button
		) {
		-webkit-appearance: none;
		margin: 0;
	}

	.wx-event-calendar-ends-a-wrapper {
		display: flex;
		margin-left: 10px;
		align-items: center;
	}

</style>
