<script>
	import { Select, Segmented } from "@wx/svelte-core";
	import { capitalizeFirstLetter, localeContext } from "@xbs/lib-scheduler";
	import { createEventDispatcher, getContext } from "svelte";
	export let mode;
	export let dropDown = false;

	const dispatch = createEventDispatcher();

	const locale = getContext(localeContext);
	const _ = locale.getGroup("scheduler");

	const config = getContext("schedulerConfig");
	$: ({ viewControl, views } = $config);
	let control;
	let localeModes;
	$: {
		localeModes = views.map(({ id, label }) => {
			const name = _(capitalizeFirstLetter(label));
			return { id, name, label: name };
		});
		switch (viewControl) {
			case "auto":
				control =
					localeModes.length > 3 || dropDown ? "dropdown" : "toggle";
				break;
			case "none":
				control = null;
				break;
			case "dropdown":
			case "toggle":
				control = viewControl;
				break;
		}
	}
	$: value = mode;

	$: if (value) {
		dispatch("action", {
			action: "set-mode",
			data: { value },
		});
	}

</script>

<div class="wx-event-calendar-nav-button">
	{#if control === 'dropdown'}
		<div class="wx-event-calendar-select-wrapper">
			<Select options={localeModes} bind:value textField="name" />
		</div>
	{:else if control === 'toggle'}
		<Segmented options={localeModes} bind:value />
	{/if}
</div>

<style>
	.wx-event-calendar-select-wrapper {
		width: max-content;
	}

</style>
