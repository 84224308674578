<script>
	import { calendarSidebarActions, localeContext } from "@xbs/lib-scheduler";

	import { form } from "@xbs/lib-svelte";
	import { Calendar } from "@wx/svelte-core";
	import { createEventDispatcher, getContext } from "svelte";
	import CalendarEditForm from "./CalendarEditForm.svelte";
	import CalendarInfo from "./CalendarInfo.svelte";
	import CalendarsItems from "./CalendarsItems.svelte";
	export let calendars = [];
	export let datepicker;
	export let sidebar;

	$: show = sidebar.show;

	const dispatch = createEventDispatcher();
	const { showModal } = getContext("wx-helpers");
	const locale = getContext(localeContext).getGroup("scheduler");

	const datepickerState = new form({ value: $datepicker.current }, update => {
		dispatch("action", {
			action: "set-date",
			data: { value: update.value },
		});
	});
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	const state = new form({ pos: null, calendar: null, edit: null }, () => {});
	$: datepickerState.reset({ value: $datepicker.current });

	function clickHandle(event) {
		calendarSidebarActions(
			event.detail,
			state,
			dispatch,
			showModal,
			$state.calendar,
			locale
		);
	}

</script>

<div class="wx-event-calendar-wrapper" class:wx-event-calendar-show={show}>
	<Calendar
		bind:value={$datepickerState.value}
		bind:current={$datepickerState.value}
		buttons={false}
		part="normal" />
	<div class="wx-event-calendar-calendars_wrapper">
		<CalendarsItems {calendars} on:action on:sidebarAction={clickHandle} />
	</div>
</div>
{#if $state.pos}
	<CalendarInfo
		pos={$state.pos}
		calendar={$state.calendar}
		on:sidebarAction={clickHandle}
		on:action />
{/if}
{#if $state.edit}
	<CalendarEditForm
		on:action
		on:alert
		calendar={$state.calendar}
		on:sidebarAction={clickHandle} />
{/if}

<style>
	.wx-event-calendar-wrapper {
		width: 0;
		flex-direction: column;
		overflow: auto;
		height: 100%;
		background-color: var(--wx-background);
		color: var(--wx-color-font);
		display: flex;
		gap: 10px;
		overflow-x: hidden;
		transition: width 0.5s ease-in-out;
	}
	.wx-event-calendar-calendars_wrapper {
		flex-grow: 1;
		overflow: hidden;
	}

	.wx-event-calendar-show {
		border-right: var(--wx-border);
		width: 249px;
	}
	:global(.wrapper) {
		--wx-calendar-padding: 20px 10px !important;
		--wx-calendar-cell-gap: 5px !important;
	}

</style>
