<script>
	import { getPopupPosition } from "@xbs/lib-dom";
	import {
		singleClickHandler,
		dblClickOnGrid,
		drag,
		localeContext,
		preSaveValidationError,
		getViewById,
		updateRecurring,
		postDragRecurring,
	} from "@xbs/lib-scheduler";
	import {
		createEventDispatcher,
		setContext,
		tick,
		getContext,
	} from "svelte";

	import Navigation from "./Navigation/Navigation.svelte";
	import Month from "./MonthView/Month.svelte";
	import BaseColumnView from "./BaseLayout/BaseColumnView.svelte";
	import EventInfoDialog from "./EventInfoDialog.svelte";
	import CalendarsSidebar from "./CalendarSidebar/CalendarsSidebar.svelte";
	import YearView from "./YearView/YearView.svelte";
	import Agenda from "./AgendaView/Agenda.svelte";
	import RecurringModal from "./RecurringModal.svelte";
	import Timeline from "./Timeline/Timeline.svelte";
	import RightSidebar from "./RightSidebar/RightSidebar.svelte";

	export let store;
	const locale = getContext(localeContext)?.getGroup("scheduler");
	const { showModal } = getContext("wx-helpers");

	let unassignedToggle = false;

	const dispatch = createEventDispatcher();

	const {
		date,
		mode,
		viewModel,
		selectedId,
		selected,
		editorShape,
		calendars,
		config,
		popupInfo,
		edit,
		datepicker,
		sidebar,
		colors,
		events,
	} = store.getReactive();

	const view = {
		day: BaseColumnView,
		week: BaseColumnView,
		month: Month,
		year: YearView,
		agenda: Agenda,
		timeline: Timeline,
	};

	$: layout = getViewById($config, $mode)?.layout;

	$: model = $viewModel;
	$: {
		setContext("schedulerConfig", config);
		setContext("schedulerColors", colors);
	}

	$: ({
		readonly,
		dragCreate,
		dragMove,
		dragResize,
		eventInfoOnClick,
		dateClick,
		autoSave,
	} = $config);

	$: skipDnD = readonly || $edit;

	let pos;
	let node;
	let clickEvent;
	let layoutNode;
	let showRecurringModal = false;
	let recurringMode = "only";
	let skipRecurringConfirm = "";

	$: if ($popupInfo && selected) {
		if (!node) node = layoutNode;
		getPos();
	}
	$: if ($mode) {
		unassignedToggle = false;
	}

	async function getPos() {
		await tick();
		pos = getPopupPosition(node, $selectedId, layoutNode, clickEvent);
	}

	function clickHandler(ev) {
		const eventNode = singleClickHandler(ev, dispatch, dateClick);
		clickEvent = eventNode ? ev : null;
		node = eventNode || layoutNode;
	}

	function dblClickHandler(ev, disableCreate) {
		let cnf = $config;
		if (disableCreate) {
			cnf = {
				...cnf,
				createEventOnDblClick: false,
			};
		}
		dblClickOnGrid(ev, dispatch, model, cnf, locale, $calendars);
	}

	let isValid;
	if (process.env.TRIALPACKAGE) {
		isValid = () => {
			if (typeof window === "undefined") return true;

			const l = location.hostname;
			const t = [
				// dhtmlx.com
				"ZGh0bWx4LmNvbQ==",
				// dhtmlxcode.com
				"ZGh0bWx4Y29kZS5jb20=",
				// webixcode.com
				"d2ViaXhjb2RlLmNvbQ==",
				// webix.io
				"d2ViaXguaW8=",
				// repl.co
				"cmVwbC5jbw==",
				// csb.app
				"Y3NiLmFwcA==",
			];

			for (let i = 0; i < t.length; i++) {
				const x = window.atob(t[i]);
				if (x === l || l.endsWith("." + x)) return true;
			}

			return false;
		};
	}

	function recurringAction(ev) {
		const { action, data } = ev.detail;
		const { event, initEvent = event } = data;
		if (autoSave) {
			const originalEvent =
				$events.find(e => e.id === event.recurringEventId) || initEvent;
			// for case this and future it dispatches action to update event action to add new recurring and select new one, but we don't want to confirm recurring mode again
			const newEventId = updateRecurring(
				event,
				initEvent,
				originalEvent,
				action,
				recurringMode,
				dispatch
			);
			if (newEventId) {
				skipRecurringConfirm = newEventId;
			}

			return;
		}
		if (
			(action === "update-event" || action === "delete-event") &&
			!showRecurringModal
		) {
			return (showRecurringModal = { event, action, initEvent });
		}
	}
	function dragProxy(initEvent, event) {
		const newEvent = { ...postDragRecurring({ initEvent, event }) };
		const err = preSaveValidationError(newEvent);
		if (err) {
			showAlert(err);
			resetDrag();
			return;
		}
		showRecurringModal = {
			action: `update-event`,
			event: newEvent,
			initEvent,
		};
	}

	function resetDrag() {
		if (model.resetToInit) {
			model.resetToInit();
		}
	}

	function closeRecurringWindow(ev) {
		const { reset, mode } = ev.detail;
		showRecurringModal = null;
		// here we catch mode from recurring modal
		recurringMode = mode;
		if (reset) {
			resetDrag();
		}
	}

	function alertHandle(ev) {
		const { message } = ev.detail;
		showAlert(message);
	}

	function showAlert(message) {
		showModal({
			message: locale(message),
			buttons: ["ok"],
		});
	}

	function toggleUnassigned() {
		unassignedToggle = !unassignedToggle;
	}

	$: if ($edit && autoSave && $selected?.recurring) {
		if ($selected.id === skipRecurringConfirm) {
			// we don't need to confirm, we just need to change mode to all since it's a new recurring event
			skipRecurringConfirm = false;
			recurringMode = "all";
		} else {
			showRecurringModal = {
				event: null,
				action: `update-event`,
				autoSave: true,
			};
		}
	}
	$: if (!$edit && autoSave) {
		// reset skipRecurringConfirm if we are not in edit mode
		skipRecurringConfirm = "";
	}

</script>

{#if process.env.TRIALPACKAGE && !isValid()}
	<mark
		class="wx-event-calendar_mark"
		class:wx-event-calendar_mark--error={process.env.TRIALDATE < new Date()}>
		{window.atob('VHJpYWw=')}
	</mark>
{/if}

<div class="wx-event-calendar-layout wx-event-calendar" bind:this={layoutNode}>
	<Navigation
		{date}
		{mode}
		{datepicker}
		toggleSidebar={$sidebar}
		title={$viewModel.title}
		on:action>
		<slot
			name="modeControl"
			slot="modeControl"
			let:dropDown
			{dropDown}
			let:mode
			{mode} />
	</Navigation>

	<div class="wx-event-calendar-content">
		<CalendarsSidebar
			{datepicker}
			{date}
			sidebar={$sidebar}
			calendars={$calendars}
			on:alert={alertHandle}
			on:action />
		<div
			class="wx-event-calendar-grid"
			on:click={clickHandler}
			on:dblclick={dblClickHandler}
			use:drag={{ triggers: ['data-resizer', 'data-id', 'data-cell'], skip: skipDnD, dispatch, model, trackMousesDiff: true, dragCreate, dragResize, dragMove, popupInfo: $popupInfo, calendars: $calendars, locale, dragProxy }}>
			<svelte:component
				this={view[layout]}
				on:toggleUnassigned={toggleUnassigned}
				unassignedToggle={unassignedToggle || $edit}
				{model}
				{selected}
				calendars={$calendars}
				on:action />
		</div>

		<div
			on:click={clickHandler}
			class="wx-event-calendar-right"
			class:wx-event-calendar-right-show={$edit || unassignedToggle}
			on:dblclick={ev => dblClickHandler(ev, true)}
			use:drag={{ triggers: ['data-id'], skip: readonly, dispatch, model, dragMove, locale, dragProxy, dragCreate: false }}>
			<RightSidebar
				{unassignedToggle}
				editMode={$edit}
				editorShape={$editorShape}
				calendars={$calendars}
				{datepicker}
				{model}
				event={$selected || {}}
				on:toggleUnassigned={toggleUnassigned}
				on:alert={alertHandle}
				on:recurringAction={recurringAction}
				on:action />
		</div>
	</div>
</div>

{#if $popupInfo && $selected && $selectedId && eventInfoOnClick && pos}
	<EventInfoDialog
		{pos}
		{readonly}
		event={$selected}
		calendars={$calendars}
		on:recurringAction={recurringAction}
		on:action />
{/if}

{#if showRecurringModal}
	<RecurringModal
		{...showRecurringModal}
		events={$events}
		on:action
		on:close-recurring-window={closeRecurringWindow} />
{/if}

<style>
	:global(.wx-event-calendar *) {
		transition: all 0.2s;
	}
	.wx-event-calendar {
		--wx-event-calendar_hour-scale-width: 70px;
		--wx-event-calendar_hour-cell-height: 42px;
		--wx-event-calendar_month-cell-min-height: 200px;
		--wx-event-calendar_agenda-scale-width: 110px;
	}

	.wx-event-calendar-layout {
		position: relative;
		display: flex;
		flex-direction: column;
		height: 100%;
		overflow: hidden;
		font-family: var(--wx-font-family);
		font-size: var(--wx-font-size);
		background-color: var(--wx-background);
		color: var(--wx-color-font);
	}

	.wx-event-calendar-content,
	.wx-event-calendar-grid {
		flex: 1;
		display: flex;
		overflow: hidden;
		position: relative;
	}

	.wx-event-calendar_mark {
		position: absolute;
		right: 34px;
		top: 12px;
		transform: rotate(30deg);
		color: #ccc;
		font-weight: 500;
		text-transform: uppercase;
		background: inherit;
		z-index: 10;
	}
	.wx-event-calendar_mark.wx-event-calendar_mark--error {
		color: red;
	}

	.wx-event-calendar-right {
		max-width: 0;
		width: 0;
	}
	.wx-event-calendar-right-show {
		max-width: 400px;
		width: 400px;
	}

</style>
