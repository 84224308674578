<script>
	import { getContext } from "svelte";
	import { getRangeTimeString, localeContext } from "@xbs/lib-scheduler";
	import { isMultiEvent } from "@xbs/lib-scheduler/dist/events";

	export let event = {};
	export const calendar = {};
	const locale = getContext(localeContext);
	const _ = locale.getGroup("scheduler");

	let time = "";
	$: {
		const allDay = event.allDay || isMultiEvent(event);
		time = allDay ? _("All day") : getRangeTimeString(event, locale);
	}

</script>

<div class="wx-event-calendar-label">
	<span class="wx-event-calendar-agenda-event-marker" />
	<div class="wx-event-calendar-agenda-event-time">{time}</div>
	<span class="wx-event-calendar-agenda-event-text"> {event.text} </span>
</div>

<style>
	.wx-event-calendar-agenda-event-marker {
		display: inline-block;
		width: 10px;
		height: 10px;
		margin-right: 5px;
		border-radius: 50%;
		flex-shrink: 0;
		background-color: var(--wx-background);
	}
	.wx-event-calendar-agenda-event-time {
		font-weight: var(--wx-font-weight-md);
		width: 120px;
		flex-shrink: 0;
		text-align: center;
	}
	.wx-event-calendar-label {
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		display: flex;
		align-items: center;
		gap: 20px;
		cursor: pointer;
		height: 100%;
	}

	.wx-event-calendar-agenda-event-text {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: normal;
	}

</style>
