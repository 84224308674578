<script>
	import { getEventStyles, readonlyConfig } from "@xbs/lib-scheduler";
	import { isPast } from "date-fns";
	import { getContext } from "svelte";

	export let event;
	export let calendars = [];

	const config = getContext("schedulerConfig");
	const tempaltes = getContext("schedulerTemplates");
	$: ({ readonly, dimPastEvents } = $config);
	$: eventData = event.eventData;
	$: calendar = calendars.find(c => c.id === eventData.type);
	$: ({ r, drag } = readonlyConfig(eventData, calendar, readonly));
	$: id = eventData?.id;

</script>

{#if !event.position.hideEvent}
	<div
		class="wx-event-calendar-event {r ? 'wx-event-calendar-readonly' : ''} wx-event-calendar-month-oneday-event"
		use:getEventStyles={{ event, backgroundFactor: 'border', calendars, dimPastEvents, isPast: isPast(eventData?.end_date) }}
		data-id={id}
		data-id-type={typeof id}
		data-drag={drag}>
		<svelte:component
			this={tempaltes.monthEvent}
			event={eventData}
			{calendar} />
	</div>
{/if}

<style>
	.wx-event-calendar-event {
		position: absolute;
		gap: 10px;
		padding: 0 6px;
		color: var(--wx-color-font);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		cursor: pointer;
		transition: top 0.2s, left 0.2s, width 0.2s;
	}

	.wx-event-calendar-readonly {
		cursor: default;
	}

</style>
