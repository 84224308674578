<script>
	import { getContext, createEventDispatcher } from "svelte";
	import { Modal, RadioButtonGroup } from "@wx/svelte-core";
	import { localeContext, updateRecurring } from "@xbs/lib-scheduler";

	export let event = {};
	export let initEvent;
	export let action = "update-event";
	export let autoSave = false;
	export let events = [];

	let mode = "only";

	const locale = getContext(localeContext);
	const _ = locale.getGroup("scheduler");
	const dispatch = createEventDispatcher();
	let options = [
		{ value: "only", label: _("only") },
		{ value: "future", label: _("future") },
		{ value: "all", label: _("all") },
	];
	let title;
	$: {
		const splitedAction = action.split("-")[0];
		title = `${_(splitedAction === "update" ? "Edit" : "Delete")} ${_(
			"recurring event"
		)}`;
	}

	function cancel(reset = true) {
		if (reset) {
			// cancel editing
			dispatch("action", { action: "edit-event" });
		}
		dispatch("close-recurring-window", { reset, mode });
	}

	function doAction() {
		if (!autoSave) {
			const originalEvent =
				events.find(e => e.id === event.recurringEventId) || initEvent;
			updateRecurring(
				event,
				initEvent,
				originalEvent,
				action,
				mode,
				dispatch
			);
			dispatch("action", { action: "edit-event" });
		}
		cancel(false);
	}

</script>

<Modal {title} {cancel} ok={doAction}>
	<div style="width: 300px;">
		<RadioButtonGroup {options} bind:value={mode} type="inline" />
	</div>
</Modal>
