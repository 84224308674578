<script>
	import { isPast } from "date-fns";
	import { getContext } from "svelte";
	import { getEventStyles, readonlyConfig } from "@xbs/lib-scheduler";

	export let event;
	export let calendars = [];
	export let selected;
	export let dragged = false;
	$: eventData = event.eventData;
	$: calendar = calendars.find(c => c.id === eventData.type);
	const config = getContext("schedulerConfig");
	const templates = getContext("schedulerTemplates");
	$: ({ readonly, dimPastEvents } = $config);

	$: ({ drag, resize, r } = readonlyConfig(eventData, calendar, readonly));
	$: id = eventData?.id;

</script>

<div
	class="wx-event-calendar-event {r ? 'wx-event-calendar-readonly' : ''} wx-event-calendar-timeline-event"
	class:wx-event-calendar-selected={$selected?.id === id}
	class:wx-timeline-dragged={dragged}
	use:getEventStyles={{ event, backgroundFactor: 'background', calendars, dimPastEvents, isPast: isPast(eventData?.end_date) }}
	data-id={id}
	data-disable-transition={true}
	data-id-type={typeof id}
	data-drag={drag}>
	<svelte:component
		this={templates.multievent}
		event={eventData}
		{calendar} />
	{#if resize}
		<i
			data-drag="source"
			class="wx-event-calendar-resizer wxi-dots-v"
			data-resizer={id} />
	{/if}
</div>

<style>
	.wx-timeline-dragged {
		z-index: 4 !important;
	}
	.wx-event-calendar-event {
		position: absolute;
		padding: 0 6px;
		border: 1px solid var(--wx-border-color);
		border-radius: 3px;
		color: var(--wx-color-primary-font);
		background-color: var(--wx-background);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	.wx-event-calendar-readonly {
		cursor: default;
	}

	.wx-event-calendar-resizer {
		display: flex;
		justify-content: center;
		flex-shrink: 0;
		position: absolute;
		bottom: 0;
		visibility: hidden;
		height: 100%;
		right: 0;
		cursor: w-resize;
		align-items: center;
	}
	.wx-event-calendar-event:hover .wx-event-calendar-resizer {
		visibility: visible;
	}
	.wx-event-calendar-selected {
		box-shadow: var(--wx-popup-shadow);
	}

</style>
